.status-box {
    background-color: #fffbe6; /* Light yellow background */
    border: 1px dashed #fadb14; /* Dashed border with yellow color */
    padding: 5px 10px;
    border-radius: 5px; /* Slightly rounded corners */
    font-family: Arial, sans-serif;
  }
  
.status-box strong {
  display: block; /* Make "Paid" appear as a separate block */
  font-weight: bold;

}

/* Default Checkbox (Unchecked and Clickable) */
.ModalCheckbox {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
}

.ModalCheckbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(180, 179, 179, 0.624);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  transition: 0.1s;
}

.ModalCheckbox::after {
  display: none;
  width: 6px;
  height: 12px;
  border-width: 0px 2px 2px 0px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-right-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  position: absolute;
  content: "";
  transform: rotate(45deg);
  top: 2px;
  left: 6.5px;
}

/* Checked and Clickable (Blue) */
.ModalCheckboxChecked {
  position: relative;
  padding-left: 24px;
}

.ModalCheckboxChecked::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid rgb(62, 151, 235);
  border-radius: 4px;
  background-color: rgb(1, 121, 255);
  transition: 0.1s;
}

.ModalCheckboxChecked::after {
  display: inline-block;
  width: 6px;
  height: 12px;
  border-width: 0px 2px 2px 0px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-right-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  position: absolute;
  content: "";
  transform: rotate(45deg);
  top: 2px;
  left: 6.5px;
}

/* Disabled and Checked (Gray) */
.ModalCheckboxDisabled {
  position: relative;
  padding-left: 24px;
  cursor: not-allowed;
}

.ModalCheckboxDisabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid rgb(122, 122, 122);
  border-radius: 4px;
  background-color: rgb(122, 122, 122);
}

.ModalCheckboxDisabled::after {
  display: inline-block;
  width: 6px;
  height: 12px;
  border-width: 0px 2px 2px 0px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-right-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  position: absolute;
  content: "";
  transform: rotate(45deg);
  top: 2px;
  left: 6.5px;
}

/* Disabled and Unchecked */
.ModalCheckboxDisabledUnchecked {
  position: relative;
  padding-left: 24px;
  cursor: not-allowed;
}

.ModalCheckboxDisabledUnchecked::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid rgb(119, 119, 119);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
}

.modal{
  --bs-modal-width: 550px !important;
}